import { render, staticRenderFns } from "./IndicadoresQualidadeATB.vue?vue&type=template&id=a51cc816&scoped=true"
import script from "./IndicadoresQualidadeATB.vue?vue&type=script&lang=js"
export * from "./IndicadoresQualidadeATB.vue?vue&type=script&lang=js"
import style0 from "./IndicadoresQualidadeATB.vue?vue&type=style&index=0&id=a51cc816&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a51cc816",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow})
